import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-基础设置-会员充值列表（带分页）
 * @param {*} params
 * @returns
 */
export function getMemberTopUpList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/vip/discount/grade/list`,
    data: params,
  });
}

/**
 * 总控-基础设置-会员充值列表（下拉选择项，不带分页）
 * @param {*} params
 * @returns
 */
export function getMemberTopUpSelectList(params = {}) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/vip/discount/grade/select`,
    params,
  });
}

/**
 * 总控-基础设置-会员充值-编辑
 * @param {*} params
 * @returns
 */
export function postMemberTopUpEdit(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/vip/discount/grade/save`,
    data: params,
  });
}

/**
 * 总控-会员管理-会员充值记录
 * @param {*} params
 * @returns
 */
export function getMemberTopUpLog(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/memberRecharge/list`,
    data: params,
  });
}

/**
 * 总控-会员管理-会员充值记录-验证手机号是否是会员
 * @param {*} params
 * @returns
 */
export function getValidMobileForMember(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/member/mobile/check`,
    params,
  });
}

/**
 * 总控-会员管理-会员充值记录-新增
 * @param {*} params
 * @returns
 */
export function postMemberTopUpCreate(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/memberRecharge/save`,
    params,
  });
}

/**
 * 总控-会员管理-会员充值记录-额度转移-额度查询
 * @param {*} params
 * @returns
 */
export function getMemberBalance(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/memberRecharge/getMemberBalance`,
    data: params,
  });
}

/**
 * 总控-会员管理-会员充值记录-额度转移-额度新增
 * @param {*} params
 * @returns
 */
export function postMemberAddMoveMoney(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/memberRecharge/setPurchaseCoinTransfer`,
    data: params,
  });
}

/**
 * 总控-会员管理-会员充值记录-审核到账
 * @param {*} params
 * @returns
 */
export function postAuditForMember(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/memberRecharge/approval`,
    params,
  });
}

/**
 * 总控-会员管理-会员充值记录-消费明细
 * @param {*} params
 * @returns
 */
export function getDeductionDetailOfMember(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/vip/consume/detail/list`,
    params,
  });
}

/**
 * 总控-会员管理-全部城市仓
 * @param {*} params
 * @returns
 */
export function getAllLogisticsList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/all/list`,
    data: params,
  });
}

/**
 * 总控-会员管理-消费明细列表
 * @param {*} params
 * @returns
 */
export function postLogisticsList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/vip/consume/logistics/list`,
    data: params,
  });
}

/**
 * 总控-会员管理-对公客户充值记录-列表
 * @param {*} params
 * @returns
 */
export function getCorporateRechargeList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/corporate/recharge/list`,
    data: params,
  });
}

/**
 * 总控-会员管理-对公客户充值记录-审核/作废
 * @param {*} params
 * @returns
 */
export function postCorporateRechargeAudit(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/corporate/recharge/audit`,
    data: params,
  });
}

/**
 * 总控-会员管理-对公客户充值记录-新增-手机号校验
 * @param {*} params
 * @returns
 */
export function postCorporateRechargeCheckMobile(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/corporate/recharge/checkMobile`,
    data: params,
  });
}

/**
 * 总控-会员管理-对公客户充值记录-新增
 * @param {*} params
 * @returns
 */
export function postCorporateRechargeSave(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/corporate/recharge/save`,
    data: params,
  });
}

/**
 * 总控-会员管理-对公客户充值记录-导出
 * @param {*} params
 * @returns
 */
export function postCorporateRechargeExport(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/corporate/recharge/export`,
    responseType: "blob",
    data: params,
  });
}
