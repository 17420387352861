var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "新增会员充值记录",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: {
                "label-width": "190px",
                model: _vm.formData,
                rules: _vm.formRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "充值手机号：", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入",
                      maxLength: "11",
                      disabled: _vm.disabledMobile,
                    },
                    on: { blur: _vm.onChangeMobile },
                    model: {
                      value: _vm.formData.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mobile", $$v)
                      },
                      expression: "formData.mobile",
                    },
                  }),
                  _vm.fullName
                    ? _c(
                        "span",
                        {
                          staticClass: "full-name",
                          attrs: { title: _vm.fullName },
                        },
                        [_vm._v(_vm._s(_vm.fullName))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市仓：", prop: "logistics_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.formData.logistics_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "logistics_id", $$v)
                        },
                        expression: "formData.logistics_id",
                      },
                    },
                    _vm._l(_vm.cityStoreList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "充值金额：", prop: "policy_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "jn-input-style",
                      attrs: { clearable: "", placeholder: "请选择" },
                      on: { change: _vm.onChangeSelect },
                      model: {
                        value: _vm.formData.policy_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "policy_id", $$v)
                        },
                        expression: "formData.policy_id",
                      },
                    },
                    _vm._l(_vm.policyList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.recharge_amount, value: item.id },
                      })
                    }),
                    1
                  ),
                  _vm.giftAmount
                    ? _c("span", { staticClass: "gift_amount" }, [
                        _vm._v("赠送金额 " + _vm._s(_vm.giftAmount)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.formData.policy_id
                ? _c(
                    "el-form-item",
                    { attrs: { label: "预计到账金额：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.lastCount,
                          callback: function ($$v) {
                            _vm.lastCount = $$v
                          },
                          expression: "lastCount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "充值凭证：", prop: "certificate" } },
                [
                  _c("b-file-upload", {
                    attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                    model: {
                      value: _vm.fileList,
                      callback: function ($$v) {
                        _vm.fileList = $$v
                      },
                      expression: "fileList",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }