<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
        <el-button @click="onHandleCreate" type="primary">新 增</el-button>
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.logistic_business_id"
          clearable
          placeholder="选择集配中心"
        >
          <el-option
            v-for="item of logisticList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.store_logistic_id"
          :filter-method="(data) => data && ajaxGetCityStoreList(data)"
          @visible-change="(data) => !data && ajaxGetCityStoreList"
          filterable
          clearable
          placeholder="选择城市仓"
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.member_name"
          clearable
          placeholder="输入用户名"
        >
        </el-input>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.recharge_name"
          clearable
          placeholder="输入操作人"
        >
        </el-input>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.min_amount"
          @blur="
            () =>
              (formData.min_amount = utilsTool.filterUnNumber(
                formData.min_amount,
                true
              ))
          "
          @change="onChangeAmt"
          clearable
          placeholder="服务费余额起"
        >
        </el-input>
        <span class="link">-</span>
        <el-input
          v-model="formData.max_amount"
          @blur="
            () =>
              (formData.max_amount = utilsTool.filterUnNumber(
                formData.max_amount,
                true
              ))
          "
          @change="onChangeAmt"
          clearable
          placeholder="服务费余额止"
        >
        </el-input>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.bank_username"
          clearable
          placeholder="输入持卡人姓名"
        >
        </el-input>
      </div>

      <div class="form-item">
        <el-input v-model="formData.mobile" clearable placeholder="输入手机号">
        </el-input>
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.source"
          filterable
          clearable
          placeholder="充值类型"
        >
          <el-option
            v-for="item in rechargeSourceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        :isFinance="isFinance"
        :isTransfer="isTransfer"
        :disabledAudit="disabledAudit"
        @on-preview="onHandlePreview"
        @on-change-audit="onHandleAudit"
        @on-details="onHandleDetails"
        @on-move-money="onHandleMoveMoney"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 新增充值记录，弹窗 start -->
    <create-member-dialog
      ref="refCreateMemberDialog"
      @on-edit-config="onHandleCreateChange"
    ></create-member-dialog>
    <!-- 新增充值记录，弹窗 end -->

    <!-- 充值凭证，弹窗 start -->
    <c-preview-file
      v-model="dialogVisible"
      :fileList="fileList"
      @input="fileList = []"
    ></c-preview-file>
    <!-- 充值凭证，弹窗 end -->

    <!-- 消费明细，弹窗 start -->
    <deduction-detail-dialog
      ref="refDeductionDetailDialog"
    ></deduction-detail-dialog>
    <!-- 消费明细，弹窗 end -->

    <!-- 额度转移，弹窗 start -->
    <move-money-dialog
      ref="refMoveMoneyDialog"
      @on-edit-config="onHandleCreateChange"
    ></move-money-dialog>
    <!-- 额度转移，弹窗 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import utilsTool from "@/utils/tools/tool";
import TableList from "./modules/table-list/index.vue";
import CreateMemberDialog from "./modules/create-member-dialog/index.vue";
import CPreviewFile from "@/components/common/CPreviewFile/index.vue";
import DeductionDetailDialog from "./modules/deduction-detail-dialog/index.vue";
import MoveMoneyDialog from "./modules/move-money-dialog/index.vue";
import { getMemberTopUpLog, postAuditForMember } from "@/api/member/index";
import { postMemberTopUpLogExport } from "@/api/export/center";
import { MEMBER_RECHARGE_TYPE } from "./utils/enum/index";

export default {
  name: "MemberTopUpList",
  components: {
    TableList,
    CreateMemberDialog,
    CPreviewFile,
    DeductionDetailDialog,
    MoveMoneyDialog,
  },
  data() {
    return {
      loadingData: false,
      disabledExport: false,
      disabledAudit: false,
      MEMBER_RECHARGE_TYPE,
      rechargeSourceList: Object.values(MEMBER_RECHARGE_TYPE), // 充值类型列表
      utilsTool,
      logisticList: [], // 集配中心列表
      cityStoreList: [], // 城市仓列表
      formData: {
        logistic_business_id: "", // 集配中心id
        store_logistic_id: "", // 城市仓id
        member_name: "", // 用户名
        recharge_name: "", // 操作人
        min_amount: "", // 服务费余额起
        max_amount: "", // 服务费余额止
        bank_username: "", // 持卡人姓名
        mobile: "", // 手机号
        source: "", // 充值类型
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
      isFinance: false, // 是否是财务
      isTransfer: false, // 是否有额度转移
      dialogVisible: false, // 充值凭证，弹窗是否展示
      fileList: [], // 充值凭证，文件
    };
  },
  watch: {
    /**
     * 如果集配中心发生改变，则重置城市仓数据
     */
    "formData.logistic_business_id"(val) {
      this.cityStoreList = [];
      this.ajaxGetCityStoreList(val);
    },
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
      this.ajaxGetLogisticList();
      this.ajaxGetCityStoreList();
    },
    /**
     * 获取集配中心列表
     */
    async ajaxGetLogisticList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticList = data;
      } catch (err) {
        console.log("ajax ajaxGetLogisticList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      }
    }, 300),
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await getMemberTopUpLog(params);
        const {
          total,
          data: list,
          is_finance: isFinance,
          is_transfer: isTransfer,
        } = data;
        this.total = total;
        this.tableData = list;
        this.isFinance = isFinance;
        this.isTransfer = isTransfer;
      } catch (err) {
        console.log("ajax getMemberTopUpLog err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 金额发生改变
     */
    onChangeAmt() {
      const failed =
        this.formData.min_amount &&
        Number(this.formData.min_amount) > this.formData.max_amount &&
        Number(this.formData.max_amount);
      if (failed) {
        this.formData.min_amount = "";
        this.formData.max_amount = "";
        this.$message.warning("起始金额不得大于截止金额");
      }
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxGetTableData();
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        const params = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
          user_name: username,
        };
        delete params.page;
        delete params.pageSize;
        await postMemberTopUpLogExport(params);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postMemberTopUpLogExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 新增
     */
    onHandleCreate() {
      this.$refs.refCreateMemberDialog.onInitData();
    },
    /**
     * 新增成功，刷新列表
     */
    onHandleCreateChange() {
      this.ajaxGetTableData();
    },
    /**
     * 财务审核到账
     */
    async onHandleAudit(data) {
      this.disabledAudit = true;
      try {
        const params = {
          recharge_record_id: data.id,
        };
        await postAuditForMember(params);
        this.ajaxGetTableData();
        // 手动修改数据状态，减少一次接口请求
        // const index = this.tableData.findIndex((item) => item.id === data.id);
        // this.tableData[index].status = AUDIT_STATUS.succeed.value;
        // this.$set(this.tableData[index], "status", AUDIT_STATUS.succeed.value);
        this.$message.success("操作成功");
      } catch (err) {
        console.log("ajax postAuditForMember err", err);
      } finally {
        this.disabledAudit = false;
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
    /**
     * 预览充值凭证
     */
    onHandlePreview(row) {
      this.dialogVisible = true;
      this.fileList = [
        {
          name: "充值凭证",
          url: row.certificate,
        },
      ];
    },
    /**
     * 查看消费明细
     */
    onHandleDetails(row) {
      this.$refs.refDeductionDetailDialog.onInitData(row.member_id);
    },
    /**
     * 额度转移
     */
    onHandleMoveMoney(row) {
      this.$refs.refMoveMoneyDialog.onInitData(row);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
