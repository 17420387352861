<template>
  <el-dialog
    title="新增会员充值记录"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        label-width="190px"
        ref="refForm"
        :model="formData"
        :rules="formRules"
      >
        <el-form-item label="充值手机号：" prop="mobile">
          <el-input
            placeholder="请输入"
            v-model="formData.mobile"
            maxLength="11"
            @blur="onChangeMobile"
            :disabled="disabledMobile"
          >
          </el-input>
          <!-- 属于会员，则回显会员姓名 -->
          <span v-if="fullName" class="full-name" :title="fullName">{{
            fullName
          }}</span>
        </el-form-item>

        <el-form-item label="城市仓：" prop="logistics_id">
          <el-select
            v-model="formData.logistics_id"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="充值金额：" prop="policy_id">
          <el-select
            class="jn-input-style"
            v-model="formData.policy_id"
            @change="onChangeSelect"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item of policyList"
              :key="item.id"
              :label="item.recharge_amount"
              :value="item.id"
            ></el-option>
          </el-select>
          <!-- 选择了充值金额，显示赠送金额 -->
          <span v-if="giftAmount" class="gift_amount"
            >赠送金额 {{ giftAmount }}</span
          >
        </el-form-item>

        <el-form-item v-if="formData.policy_id" label="预计到账金额：" required>
          <el-input v-model="lastCount" disabled></el-input>
        </el-form-item>

        <el-form-item label="充值凭证：" prop="certificate">
          <b-file-upload
            :accepts="['jpg', 'jpeg', 'png']"
            :limit="1"
            v-model="fileList"
          ></b-file-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import BFileUpload from "@/components/business/BFileUpload/index.vue";
import { IS_MIN_APP_SHOW, LOGISTICS_STATUS } from "../../utils/enum/index";
import {
  getMemberTopUpSelectList,
  getValidMobileForMember,
  postMemberTopUpCreate,
  getAllLogisticsList,
} from "@/api/member/index";
import { validMobile } from "@/utils/tools/validate";

export default {
  name: "CreateMemberDialog",
  props: {},
  components: {
    BFileUpload,
  },
  data() {
    return {
      dialogVisible: false,
      IS_MIN_APP_SHOW,
      configList: Object.values(IS_MIN_APP_SHOW),
      formData: {
        mobile: "", // 充值手机号
        logistics_id: "", // 城市仓id
        policy_id: "", // 充值金额
        certificate: "", // 充值凭证
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      policyList: [], // 充值金额档位下拉列
      cityStoreList: [], // 城市仓列表
      fullName: "", // 手机号对应的会员名字
      giftAmount: "", // 充值档位对应的赠送金额
      lastCount: "", // 预计到账
      fileList: [], // 凭证文件列
      submitLoading: false, // 提交按钮禁用
      disabledMobile: false, // 手机号输入框禁用
      formRules: {
        mobile: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!validMobile(value)) {
                callback(new Error("请输入符合规范的手机号！"));
                this.fullName = "";
              }
              callback();
              // console.log("🆒 mobile", value, validMobile(value));
            },
          },
        ],
        logistics_id: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择城市仓",
          },
        ],
        policy_id: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择充值金额",
          },
        ],
        /**
         * 充值凭证
         */
        certificate: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              // console.log("🆒 fileList 111", this.formData, this.fileList);
              if (!this.fileList.length) {
                callback(new Error("请上传充值凭证！"));
              }
              callback();
            },
          },
        ],
      }, // 表单校验
    };
  },
  mounted() {
    this.ajaxGetCityStoreList();
  },
  methods: {
    /**
     * 初始化
     */
    onInitData() {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      this.ajaxGetMemberTopUpSelectList();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refForm?.clearValidate();
      this.formData = {
        ...this.formDataClone,
      };
      this.fullName = "";
      this.giftAmount = "";
      this.fileList = [];
      this.dialogVisible = false;
    },
    /**
     * 获取城市仓列表
     */
    async ajaxGetCityStoreList() {
      const params = {
        status: LOGISTICS_STATUS.common.value,
      };
      try {
        const { data } = await getAllLogisticsList(params);
        this.cityStoreList = data;
        console.log("ajx ajaxGetCityStoreList", data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      }
    },
    /**
     * 获得充值档位下拉列
     */
    async ajaxGetMemberTopUpSelectList() {
      try {
        const { data } = await getMemberTopUpSelectList();
        this.policyList = data;
      } catch (err) {
        console.log("ajax getMemberTopUpSelectList err", err);
      }
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        // console.log("🆒 onHandleSubmit", this.formData);
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        this.ajaxPostMemberTopUpCreate();
      });
    },
    /**
     * 修改了手机号
     */
    onChangeMobile(e) {
      const data = e.target.value;
      // 符合条件的触发接口查询
      validMobile(data) && this.ajaxGetValidMobileForMember(data);
      // console.log("🆒 onChangeMobile", data);
    },
    /**
     * 手机号校验是否会员，返回姓名
     */
    async ajaxGetValidMobileForMember(data) {
      // 接口调用时，禁用输入
      this.disabledMobile = true;
      const params = {
        mobile: data,
      };
      try {
        const { data } = await getValidMobileForMember(params);
        const { fullname, logistics_id, purchase_fee_amount } = data;
        this.fullName = fullname;
        this.formData.logistics_id = logistics_id;
        // console.log("🆒 ajaxGetValidMobileForMember", data);
        if (purchase_fee_amount && purchase_fee_amount > 0) {
          this.$confirm(`当前已是会员，请确认是否继续充值！`, "提示", {
            center: true,
            confirmButtonText: "确 认",
            showCancelButton: false,
            customClass: "jn-confirm-message-style",
            iconClass: "el-icon-warning",
          })
            .then(() => {})
            .catch();
        }
      } catch (err) {
        console.log("ajax getValidMobileForMember err", err);
      } finally {
        // 解除禁用
        this.disabledMobile = false;
      }
    },
    /**
     * 选择了充值金额
     */
    onChangeSelect(data) {
      if (!data) {
        this.giftAmount = "";
        return;
      }
      const findItem = this.policyList.find((item) => item.id === data);
      const { recharge_amount, gift_amount } = findItem;
      this.giftAmount = gift_amount;
      this.lastCount = parseInt(recharge_amount) + parseInt(gift_amount);
    },
    /**
     * 会员充值，新增
     */
    async ajaxPostMemberTopUpCreate() {
      const { mobile, logistics_id, policy_id } = this.formData;
      const params = {
        mobile,
        logistics_id,
        policy_id,
        certificate: this.fileList[0]?.url,
      };
      try {
        await postMemberTopUpCreate(params);
        this.$message.success("新增成功");
        this.onHandleClose();
        this.$emit("on-edit-config");
      } catch (err) {
        console.log("ajax postMemberTopUpCreate err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding: 30px 20px 20px;
    /deep/ .el-form {
      &-item {
        .el-select,
        .el-input {
          width: 350px;
        }
        &__content {
          display: flex;
          line-height: normal;
        }
        .full-name {
          position: relative;
          left: 10px;
          max-width: 150px;
          line-height: 36px;
          @include ellipsis-lines;
          color: red;
        }
        .gift_amount {
          position: relative;
          left: 10px;
          line-height: 36px;
          color: red;
        }
      }
    }
  }
}
</style>
