<template>
  <el-dialog
    title="充值消费明细"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="70%"
    class="dialog"
    center
  >
    <div class="contents" v-loading="loadingData">
      <!-- 顶部操作栏 start -->
      <section class="form jn-form-style">
        <div class="form-item">
          <el-input
            v-model="formData.order_no"
            clearable
            placeholder="输入订单号"
          >
          </el-input>
        </div>

        <div class="form-item">
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="支付日期起"
            end-placeholder="支付日期止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            v-model="payDate"
            @change="changePayDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>

        <div class="form-item btn-wrap">
          <el-button
            type="primary"
            style="margin-left: 5px"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
      </section>
      <!-- 顶部操作栏 end -->

      <!-- 列表 start -->
      <div class="table-wrap">
        <el-table
          :data="tableData"
          height="100%"
          :class="{ 'no-data': !total }"
          :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
          border
        >
          <el-table-column type="index" align="center" width="100" label="序号">
          </el-table-column>
          <el-table-column
            prop="order_no"
            align="center"
            min-width="150"
            label="订单号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="pay_time"
            align="center"
            label="支付时间"
            width="240"
          >
          </el-table-column>
          <el-table-column
            prop="order_amt"
            align="center"
            label="订单金额"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="purchase_deduction_amount"
            align="center"
            label="服务费金额"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="purchase_fee_account_amount"
            align="center"
            label="服务费余额"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="purchase_deduction_type_txt"
            align="center"
            label="类型"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="order_id"
            align="center"
            label="订单详情"
            min-width="100"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="detail-btn"
                @click="onHandleDetail(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 end -->

      <!-- 分页 start -->
      <div class="pagination">
        <el-pagination
          type="primary"
          :current-page="formData.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="formData.pageSize"
          :total="total"
          @size-change="onHandleSizeChange"
          @current-change="onHandleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          background
        >
        </el-pagination>
      </div>
      <!-- 分页 end -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onHandleClose">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { getDeductionDetailOfMember } from "@/api/member/index";
// import { mockData } from "../../mock";

export default {
  name: "DeductionDetailDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      loadingData: false,
      formData: {
        member_id: "", // 会员id
        order_no: "", // 订单号
        start_time: "", // 支付时间起
        end_time: "", // 支付时间止
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      payDate: [], // 支付起止日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }, // 时间范围
      tableData: [],
      total: 0,
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(memberId) {
      this.dialogVisible = true;
      this.formData.member_id = memberId;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      this.ajaxGetTableData();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refForm?.clearValidate();
      this.formData = {
        ...this.formDataClone,
      };
      this.payDate = [];
      this.dialogVisible = false;
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.payDate = [];
      this.ajaxGetTableData();
    },
    /**
     * 支付日期发生变化
     */
    changePayDate(data) {
      this.formData.start_time = data?.[0];
      this.formData.end_time = data?.[1];
    },
    /**
     * 获得充值明细
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await getDeductionDetailOfMember(params);
        const { total, data: list } = data;
        this.total = total;
        this.tableData = list;
      } catch (err) {
        console.log("ajax getDeductionDetailOfMember err", err);
      } finally {
        this.loadingData = false;
        // 模拟数据
        // this.total = mockData.total;
        // this.tableData = mockData.tableData;
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
    /**
     * 跳转订单详情（打开新窗口）
     */
    onHandleDetail(row) {
      const params = {
        path: "/orderDetail",
        query: {
          id: row.order_id,
          hide: true,
        },
      };
      const routerInfo = this.$router.resolve(params);
      window.open(routerInfo.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  &__body {
    overflow: hidden;
  }
}
.dialog {
  .contents {
    height: 600px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .table-wrap {
      height: 430px;
      // flex: 1;
    }
    .form {
      padding: 13px 0;
    }
    /deep/ .el-table {
      height: 100%;
      &.no-data {
        border-right: 1px solid #ebeef5;
      }
    }
    .detail-btn {
      padding: 0;
      span {
        height: 36px;
        line-height: 36px;
      }
    }
    .pagination {
      // position: relative;
      // top: 10px;
    }
  }
}
</style>
