/** 是否开启设置 */
export const IS_MIN_APP_SHOW = {
  yes: {
    value: 1,
    label: '是'
  },
  no: {
    value: 0,
    label: '否'
  },
}

/** 审核状态 */
export const AUDIT_STATUS = {
  waiting: {
    value: 0,
    label: '审核中'
  },
  succeed: {
    value: 1,
    label: '已审核'
  },
}

/** 审核状态 */
export const LOGISTICS_STATUS = {
  disabled: {
    value: '0',
    label: '禁用'
  },
  common: {
    value: '1',
    label: '启用'
  },
  all: {
    value: 'all',
    label: '全部'
  }
}

/** 会员充值类型 */
export const MEMBER_RECHARGE_TYPE = {
  recharge: {
    value: 1,
    label: '对公转账'
  },
  qrCode: {
    value: 2,
    label: '银联扫码'
  },
  move: {
    value: 3,
    label: '转移所得'
  }
}
