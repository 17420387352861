var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "额度转移",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "50%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "refForm",
          staticClass: "contents",
          attrs: {
            "label-width": "170px",
            model: _vm.formData,
            rules: _vm.formRules,
          },
        },
        [
          _c("section", { staticClass: "form-item-gap" }, [
            _c("div", [_vm._v("被转移用户名：" + _vm._s(_vm.memberName))]),
            _c("div", [_vm._v("城市仓：" + _vm._s(_vm.cityStoreName))]),
            _c("div", [_vm._v("服务币余额：" + _vm._s(_vm.memberBalance))]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "转入手机号：", prop: "mobile" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "输入转入手机号",
                      maxLength: "11",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mobile", $$v)
                      },
                      expression: "formData.mobile",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("el-form-item", { attrs: { label: "城市仓：" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { placeholder: "输入城市仓", disabled: "" },
                  model: {
                    value: _vm.cityStoreName,
                    callback: function ($$v) {
                      _vm.cityStoreName = $$v
                    },
                    expression: "cityStoreName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "转移金额：", prop: "count" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入转移金额", clearable: "" },
                    model: {
                      value: _vm.formData.count,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "count", $$v)
                      },
                      expression: "formData.count",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }