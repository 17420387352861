<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column type="index" align="center" width="100" label="序号">
    </el-table-column>
    <el-table-column
      prop="member_name"
      align="center"
      width="100"
      label="用户名"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="member_mobile"
      align="center"
      label="手机号"
      width="160"
    >
    </el-table-column>
    <el-table-column
      prop="logistic_business_name"
      align="center"
      label="集配中心"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistic_business_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="logistic_name"
      align="center"
      label="城市仓"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistic_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="recharge_amount"
      align="center"
      label="充值金额"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="gift_amount"
      align="center"
      label="赠送金额"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="purchase_fee_amount"
      align="center"
      label="服务费余额"
      min-width="120"
    >
    </el-table-column>
    <el-table-column
      prop="purchase_deduction_amount"
      align="center"
      label="下单抵扣服务费金额"
      min-width="150"
    >
    </el-table-column>
    <el-table-column
      prop="recharge_name"
      align="center"
      label="充值操作人"
      min-width="100"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="logistic_bank_username"
      align="center"
      label="持卡人姓名"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistic_bank_username || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="certificate"
      align="center"
      label="充值凭证"
      width="120"
    >
      <template slot-scope="scope">
        <el-button
          v-if="scope.row.source === MEMBER_RECHARGE_TYPE.recharge.value"
          type="text"
          @click="onHandleView(scope.row)"
          >点击查看</el-button
        >
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="bill_no"
      align="center"
      label="流水单号"
      min-width="150"
    >
      <template slot-scope="scope">
        {{ scope.row.bill_no || "-" }}
      </template>
    </el-table-column>
    <el-table-column prop="source" align="center" label="充值类型" width="120">
      <template slot-scope="scope">
        <span>{{
          rechargeSourceList.find(
            (item) =>
              item.value ===
              (scope.row.source || MEMBER_RECHARGE_TYPE.recharge.value)
          ).label
        }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="status" align="center" label="充值状态" width="120">
      <template slot-scope="scope">
        <span>{{
          statusMap.find(
            (item) =>
              item.value === (scope.row.status || AUDIT_STATUS.waiting.value)
          ).label
        }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="purchase_deduction_amount"
      align="center"
      label="消费明细"
      width="120"
    >
      <template slot-scope="scope">
        <div>
          <el-button type="text" @click="onHandleDetail(scope.row)"
            >查看详情</el-button
          >
        </div>
        <div>
          <el-button
            type="text"
            v-if="isTransfer"
            @click="onHandleMoveMoney(scope.row)"
            >额度转移</el-button
          >
        </div>
      </template>
    </el-table-column>

    <!-- 只有财务展示操作栏 start -->
    <el-table-column
      v-if="isFinance"
      label="到账状态"
      width="160"
      align="center"
    >
      <template slot-scope="scope">
        <el-button
          type="warning"
          size="medium"
          v-if="scope.row.status === AUDIT_STATUS.waiting.value"
          @click="onHandleEditData(scope.row)"
          >未到账</el-button
        >
        <el-button type="primary" size="medium" v-else :disable="disabledAudit"
          >已到账</el-button
        >
      </template>
    </el-table-column>
    <!-- 只有财务展示操作栏 end -->
  </el-table>
</template>

<script>
import { AUDIT_STATUS, MEMBER_RECHARGE_TYPE } from "../../utils/enum/index";

export default {
  name: "TableList",
  props: {
    /**
     * 是否是财务，只有财务，展示操作栏
     */
    isFinance: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否禁用审核按钮
     */
    disabledAudit: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否拥有额度转移功能
     */
    isTransfer: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      AUDIT_STATUS,
      MEMBER_RECHARGE_TYPE,
      statusMap: Object.values(AUDIT_STATUS), // 状态列
      rechargeSourceList: Object.values(MEMBER_RECHARGE_TYPE), // 充值类型列表
    };
  },
  methods: {
    /**
     * 财务审核到账
     */
    onHandleEditData(row) {
      this.$confirm("操作前，请确认充值是否到账！", "提示", {
        center: true,
        confirmButtonText: "确 认",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      })
        .then(() => {
          this.$emit("on-change-audit", row);
        })
        .catch();
    },
    /**
     * 预览充值凭证
     */
    onHandleView(row) {
      this.$emit("on-preview", row);
    },
    /**
     * 消费明细
     */
    onHandleDetail(row) {
      this.$emit("on-details", row);
    },
    /**
     * 额度转移
     */
    onHandleMoveMoney(row) {
      this.$emit("on-move-money", row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
