/**
 * 匹配文本中的空格
 * @param {String} str 字符串 -必传
 * @returns {Boolean}
 */
export function validStrSpace(str) {
  const reg = /(^\s+)|(\s+$)|\s+/g;
  return str && reg.test(str);
}

/**
 * 匹配大于等于0的整数
 * @param {String || Number} str 字符串或数字 -必传
 * @returns {Boolean}
 */
export function validInteger(str) {
  // const reg = /\D|^0/g;
  const reg = /\D/g;
  const res = reg.test(str);
  // console.log('🆒 validInteger', str, res);
  return res;
}

/**
 * 匹配大于等于0的金额，可以带两位小数点
 * @param {String || Number} str 字符串或数字 -必传
 * @param {Boolean} zero 是否非0 -非必传
 * @returns {Boolean}
 */
export function validMoney(str, zero) {
  let reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/;
  if (zero) {
    reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/g;
  }
  let res = reg.test(str);
  return res;
}

/**
 * 匹配手机号
 * @param {String || Number} str 字符串或数字 -必传
 * @returns {Boolean}
 */
export function validMobile(str) {
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  const res = reg.test(str);
  return res;
}
