<template>
  <el-dialog
    title="额度转移"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <el-form
      class="contents"
      label-width="170px"
      ref="refForm"
      :model="formData"
      :rules="formRules"
    >
      <section class="form-item-gap">
        <div>被转移用户名：{{ memberName }}</div>
        <div>城市仓：{{ cityStoreName }}</div>
        <div>服务币余额：{{ memberBalance }}</div>
      </section>

      <el-form-item label="转入手机号：" prop="mobile">
        <div>
          <el-input
            v-model="formData.mobile"
            placeholder="输入转入手机号"
            maxLength="11"
            clearable
          ></el-input>
        </div>
      </el-form-item>

      <el-form-item label="城市仓：">
        <div>
          <el-input
            v-model="cityStoreName"
            placeholder="输入城市仓"
            disabled
          ></el-input>
        </div>
      </el-form-item>

      <el-form-item label="转移金额：" prop="count">
        <div>
          <el-input
            v-model="formData.count"
            placeholder="输入转移金额"
            clearable
          ></el-input>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { LOGISTICS_STATUS } from "../../utils/enum";
import { validMobile, validMoney } from "@/utils/tools/validate";
import { getMemberBalance, postMemberAddMoveMoney } from "@/api/member/index";

export default {
  name: "MoveMoneyDialog",
  props: {},
  data() {
    return {
      LOGISTICS_STATUS,
      dialogVisible: false,
      submitLoading: false, // 提交按钮禁用
      memberName: "", // 转移账户名称
      cityStoreName: "", // 城市仓名称
      memberBalance: 0, // 转移账户的余额
      // 表单数据
      formData: {
        member_id: "", // 当前转移会员id
        mobile: "", // 转入手机号
        count: "", // 转移金额
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      // 表单校验
      formRules: {
        mobile: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!validMobile(value)) {
                callback(new Error("请输入符合规范的手机号！"));
                this.fullName = "";
              }
              callback();
              // console.log("🆒 mobile", value, validMobile(value));
            },
          },
        ],
        count: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入转移金额！"));
              }
              if (value > this.memberBalance) {
                callback(new Error("转移金额不得大于余额！"));
              }
              if (!validMoney(value, true)) {
                callback(new Error("金额需大于0，允许两位小数点！"));
              }
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      const { member_id, member_name, member_mobile, logistic_name } = data;
      this.formData.member_id = member_id;
      this.memberName = `${member_name}（${member_mobile}）`;
      this.cityStoreName = logistic_name;
      this.ajaxGetMemberBalance();
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.resetFields();
    },
    /**
     * 查询账户余额
     */
    async ajaxGetMemberBalance() {
      const params = {
        memberId: this.formData.member_id,
      };
      try {
        const { data } = await getMemberBalance(params);
        const count = Number((data?.memberBalance || 0) / 100);
        this.memberBalance = count;
      } catch (err) {
        console.log("ajax getMemberBalance err", err);
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refForm?.clearValidate();
      this.formData = {
        ...this.formDataClone,
      };
      this.memberName = "";
      this.cityStoreName = "";
      this.memberBalance = 0;
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        this.ajaxPostAddMoveMoney();
      });
    },
    /**
     * 新增转移记录
     */
    async ajaxPostAddMoveMoney() {
      const {
        member_id: currentMemberId,
        mobile: targetMemberMobile,
        count,
      } = this.formData;
      const transferAmount = Number((Number(count) * 100).toFixed(0));
      const params = {
        currentMemberId,
        targetMemberMobile,
        transferAmount,
      };
      try {
        await postMemberAddMoveMoney(params);
        this.$message.success("新增成功");
        this.onHandleClose();
        this.$emit("on-edit-config");
      } catch (err) {
        // 失败后，实时更新余额
        this.ajaxGetMemberBalance();
        console.log("ajax postMemberAddMoveMoney err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding: 30px 20px 20px;
    .form-item-gap {
      display: flex;
      justify-content: center;
      gap: 25px;
      margin-bottom: 40px;
      div {
        font-size: 18px;
        font-weight: 550;
      }
    }
    /deep/ .el-form {
      &-item {
        .el-select,
        .el-input {
          width: 500px;
        }
        &__content {
          display: flex;
          line-height: normal;
        }
      }
    }
  }
}
</style>
